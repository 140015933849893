let ContextMenu = (function () {
    let _private = {};

    let instance = this;

    _private.links = {};

    _private.options = {};

    _private.init = function () {
        _private.contextMenu = _private.getElement();
        if (_private.contextMenu.hasClass('inactive')) {
            return;
        }
        if (_private.contextMenu.find('li').length === 0) {
            _private.contextMenu.hide();
            return;
        }
        _private.contextMenu.show();
    };

    _private.setLink = function (linkKey, link) {
        if (!_.isObject(link) || _.isUndefined(link.label) || _.isUndefined(link.url)) {
            return;
        }
        if (!_.isUndefined(link.if) && !link.if) {
            return;
        }
        _private.links[linkKey] = link;
    };

    _private.clear = function () {
        _private.links       = {};
        _private.contextMenu = _private.getElement();
        _private.contextMenu.find('li').remove();
        instance.init();
    };

    _private.hide = function () {
        _private.contextMenu = _private.getElement();
        _private.contextMenu.hide();
    };

    _private.show = function () {
        _private.contextMenu = _private.getElement();
        _private.contextMenu.show();
    };

    _private.setContentMenu = function (options) {
        _private.options = options;
        let contextMenu  = _private.getElement().find('ul.contextmenu:first');
        $(contextMenu).html('');
        $.each(options, function (linkKey, opt) {
            let liElement = $('<li/>');
            if (typeof opt.liClass === 'string') {
                liElement.addClass(opt.liClass);
            }
            if (_.isString(opt.html)) {
                liElement.append(opt.html);
                contextMenu.append(liElement);
                return true;
            }
            let aElement = $('<a/>');
            if (_.isUndefined(_private.links[linkKey]) && _.isUndefined(opt.label)) {
                return true;
            }
            let linkData = $.extend(true, {}, _private.links[linkKey], opt);
            if (!_.isUndefined(opt.params)) {
                linkData.url = _private.replaceLinkParams(linkData.url, opt.params);
            }
            aElement.attr('href', linkData.url);
            aElement.text(linkData.label);
            if (!_.isUndefined(linkData.class)) {
                aElement.addClass(linkData.class);
            }
            if (linkData.blank) {
                aElement.attr('target', '_blank');
            }
            if (!_.isUndefined(opt.click) && _.isFunction(opt.click)) {
                aElement.click(function (event) {
                    if (_.isUndefined(opt.clickArgs)) {
                        opt.clickArgs = [];
                    }
                    event.preventDefault();
                    let args = $.extend([], opt.clickArgs);
                    args.push(event);
                    opt.click.apply(this, args);
                });
            }
            liElement.append(aElement);
            contextMenu.append(liElement);
            return true;
        });
    };

    _private.getContentMenuOptions = function () {
        return _private.options;
    };

    _private.replaceLinkParams = function (link, params) {
        $.each(params, function (key, value) {
            link = link.replace('#' + key + '#', value);
        });
        return link;
    };

    _private.getElement = function () {
        if (!_private.contextMenu) {
            _private.contextMenu = $('.content-header').find('div.global-contextmenu');
        }
        return _private.contextMenu
    };

    return _private;

})();